// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './Menu.module.scss';

type Props = {
  menu: {
    label: string,
    path: string
  }[]
};

const Menu = ({ menu }: Props) => (
  <nav className={styles['menu']}>
    <ul className={styles['menu__list']}>
      {menu.map((item) => (
        <li className={styles['menu__list-item']} key={item.path}>
          <Link
            to={item.path}
            className={styles['menu__list-item-link']}
            activeClassName={styles['menu__list-item-link--active']}
          >
            {item.label}
          </Link>
        </li>
      ))}
      <li style={{marginTop: 36}} className={styles['menu__list-item']} key="spotify">
        <Link
          to="https://open.spotify.com/show/0kAjo2watz2ZTjDOZLDaTu"
          className={styles['menu__list-item-link']}
          activeClassName={styles['menu__list-item-link--active']}
        >
          Auf Spotify anhören
        </Link>
      </li>
      <li className={styles['menu__list-item']} key="spotify">
        <Link
          to="https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc29sY2FzdC5kZS9yc3MueG1s"
          className={styles['menu__list-item-link']}
          activeClassName={styles['menu__list-item-link--active']}
        >
          Auf Google Podcast anhören
        </Link>
      </li>
      <li className={styles['menu__list-item']} key="spotify">
        <Link
          to="https://podcasts.apple.com/us/podcast/solana-nft-gaming-podcast-solcast/id1599952907"
          className={styles['menu__list-item-link']}
          activeClassName={styles['menu__list-item-link--active']}
        >
          Auf Apple Podcast anhören
        </Link>
      </li>
    </ul>
  </nav>
);

export default Menu;
